import React from 'react';
import { motion } from 'framer-motion';

import { MotionDiv } from '../../animations/components';
import { animationContainer, fadeInUp } from '../../animations/variants';
import { ScContainer } from '../../components/container/styled';
import { ExplanationCard } from '../../components/ExplanationCard';
import { ScTitle } from '../../components/Title/styled';
import useContentAnimation from '../../hooks/use-content-animation';

import { InfoSectionCookie } from './InfoSectionCookie';
import { ScBenefitsTextWrapper, ScLastUpdateWrapper, TheCookiesWeSetWrapper } from './styled';

const cards = [
	{
		title: 'Account related cookies',
		text: [
			`<p>If you create an account with us then we will use cookies for the management of the signup process and general administration. These cookies will usually be deleted when you log out however in some cases they may remain afterwards to remember your site preferences when logged out.</p>`,
		],
	},
	{
		title: 'Login related cookies',
		text: [
			`<p>We use cookies when you are logged in so that we can remember this fact. This prevents you from having to log in every single time you visit a new page. These cookies are typically removed or cleared when you log out to ensure that you can only access restricted features and areas when logged in.</p>`,
		],
	},
	{
		title: 'Email newsletter related cookies',
		text: [
			`<p>This site offers newsletter or email subscription services and cookies may be used to remember if you are already registered and whether to show certain notifications which might only be valid to subscribed/unsubscribed users.</p>`,
		],
	},
	{
		title: 'Forms related cookies',
		text: [
			`<p>When you submit data to through a form such as those found on contact pages or comment forms cookies may be set to remember your user details for future correspondence.</p>`,
		],
	},
	{
		title: 'Site preferences cookies',
		text: [
			`<p>In order to provide you with a great experience on this site we provide the functionality to set your preferences for how this site runs when you use it. In order to remember your preferences we need to set cookies so that this information can be called whenever you interact with a page is affected by your preferences.</p>`,
		],
	},
	{
		title: 'Third Party Cookies',
		text: [
			`<p>In some special cases we also use cookies provided by trusted third parties. The following section details which third party cookies you might encounter through this site.</p>
            <ul>
                <li>This site uses Google Analytics which is one of the most widespread and trusted analytics solutions on the web for helping us to understand how you use the site and ways that we can improve your experience. These cookies may track things such as how long you spend on the site and the pages that you visit so we can continue to produce engaging content. For more information on Google Analytics cookies, see the official Google Analytics page.</li>
                <li>Third party analytics are used to track and measure usage of this site so that we can continue to produce engaging content. These cookies may track things such as how long you spend on the site or pages you visit which helps us to understand how we can improve the site for you.</li>
                <li>From time to time we test new features and make subtle changes to the way that the site is delivered. When we are still testing new features these cookies may be used to ensure that you receive a consistent experience whilst on the site whilst ensuring we understand which optimisations our users appreciate the most.</li>
                <li>The Google AdSense service we use to serve advertising uses a DoubleClick cookie to serve more relevant ads across the web and limit the number of times that a given ad is shown to you. For more information on Google AdSense see the official Google AdSense privacy FAQ.</li>
                <li>We are a part of some retailer's affiliate programmes. Therefore, we may use affiliate tracking cookies to allow retailers to see if our users have come to their site and made a purchase.</li>
                <li>We also use social media buttons and/or plugins on this site that allow you to connect with your social network in various ways. For these to work social media sites including, but not limited to, Facebook, Instagram and YouTube, will set cookies through our site which may be used to enhance your profile on their site or contribute to the data they hold for various purposes outlined in their respective privacy policies.</li></ul>`,
		],
	},
];

export const TheCookiesWeSet = () => {
	const { ref, controls } = useContentAnimation();

	return (
		<ScContainer>
			<motion.section initial="hidden" animate={controls} variants={animationContainer}>
				<TheCookiesWeSetWrapper ref={ref}>
					<ScBenefitsTextWrapper>
						<MotionDiv variants={fadeInUp}>
							<ScTitle centered>The Cookies We Set</ScTitle>
						</MotionDiv>
					</ScBenefitsTextWrapper>
				</TheCookiesWeSetWrapper>
				{cards.map((item) => (
					<MotionDiv key={item.title} variants={fadeInUp}>
						<ExplanationCard title={item.title} text={item.text} key={item.title} />
					</MotionDiv>
				))}
				<InfoSectionCookie
					isSmallTitle
					title="More Information"
					text={`If there is something that you aren't sure whether you need or not it's usually safer to leave cookies enabled in case it does interact with one of the features you use on our site. However if you are still looking for more information then you can contact us through our email address, <br/><a href="mailto: hello@gogeta.bike" style="color: #28272F; font-weight: 600">hello@gogeta.bike</a>`}
				/>
				<ScLastUpdateWrapper>
					<strong>Last updated: 12/10/2023</strong>
				</ScLastUpdateWrapper>
			</motion.section>
		</ScContainer>
	);
};
